/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useFormik } from 'formik';
import TextField from 'components/Core/TextField';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import { getFormValid } from 'utils/formUtils';
import { BCSteps } from '../styles';

export const StepTotalAssets = (props) => {
  const formik = useFormik({
    initialValues: {
      totalAssets: props.values.totalAssets,
    },
    validate: (values) => {
      if (!values.totalAssets || values.totalAssets <= 0) {
        return { totalAssets: 'Total value of asset is invalid' };
      }
      return {};
    },
    onSubmit: (values) => {
      props.onClickNext(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, parseFloat(newValue.replace(/\$|,/g, '')));
  };

  const onHandleKeyPressed = (event) => {
    if (event.key === 'Enter') {
      formik.submitForm();
    }
  };

  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }
  };

  const formValid = getFormValid({ ...formik });

  return (
    <BCSteps>
      <FormLabelWithField
        formControlProps={{ className: 'bc-step--field-question-control' }}
        className="bc-step--field-question"
        label="What is the total value of your company’s assets?"
        isRequired={false}
      />
      <p className="bc-step--field-description">
        This includes the current value of everything you own. Consider which assets you’d sell if
        you sold your business.
      </p>
      <TextField
        className="bc-step--field-text"
        id="totalAssets"
        type="currency"
        nameType="Total value of Assets"
        placeholderText="Enter Amount"
        values={formik.values}
        formValid={formValid}
        formErrors={formik.errors}
        handleChange={onHandleChange}
        handleKeyPress={onHandleKeyPressed}
        autoFocus
      />
      <div className="bc-step--button-group">
        <Button
          className="bc-step--button-back"
          type="white"
          shadow={false}
          onDebouncedClick={onClickBack}
        >
          Back
        </Button>
        <Button
          className="bc-step--button-next"
          type="secondary"
          shadow={false}
          onDebouncedClick={() => formik.submitForm()}
        >
          Continue
        </Button>
      </div>
    </BCSteps>
  );
};
