/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useFormik } from 'formik';
import TextField from 'components/Core/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import { getFormValid } from 'utils/formUtils';
import { BCSteps } from '../styles';

export const StepRevenue = (props) => {
  const formik = useFormik({
    initialValues: {
      revenue: props.values.revenue,
    },
    validate: (values) => {
      if (!values.revenue || values.revenue <= 0) {
        return { revenue: 'Revenue is invalid' };
      }
      return {};
    },
    onSubmit: (values) => {
      props.onClickNext(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, parseFloat(newValue.replace(/\$|,/g, '')));
  };

  const onHandleKeyPressed = (event) => {
    if (event.key === 'Enter') {
      formik.submitForm();
    }
  };

  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }
  };

  const formValid = getFormValid({ ...formik });

  return (
    <BCSteps>
      <FormLabelWithField
        data-testid="bc_revenue_label"
        formControlProps={{ className: 'bc-step--field-question-control' }}
        className="bc-step--field-question"
        label="What was your company’s revenue last year?"
        isRequired={false}
      />
      <p data-testid="bc_revenue_description" className="bc-step--field-description">
        Company revenue includes all of the sales your company made for the year.
      </p>
      <TextField
        className="bc-step--field-text"
        id="revenue"
        type="currency"
        nameType="Revenue"
        placeholderText="Enter Amount"
        values={formik.values}
        formValid={formValid}
        formErrors={formik.errors}
        handleChange={onHandleChange}
        handleKeyPress={onHandleKeyPressed}
        autoFocus
      />
      <div className="bc-step--button-group">
        <Button
          data-testid="bc_back"
          className="bc-step--button-back"
          type="white"
          shadow={false}
          onDebouncedClick={onClickBack}
        >
          Back
        </Button>
        <Button
          data-testid="bc_continue"
          className="bc-step--button-next"
          type="secondary"
          shadow={false}
          onDebouncedClick={() => formik.submitForm()}
        >
          Continue
        </Button>
      </div>
    </BCSteps>
  );
};
