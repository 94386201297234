/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'components/Core/Select';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import { getFormValid } from 'utils/formUtils';
import { BCSteps } from '../styles';

export const StepAdditional = (props) => {
  const existingUserOptions = [
    { label: 'No', value: 'No' },
    { label: 'Yes', value: 'Yes' },
  ];

  const formik = useFormik({
    initialValues: {
      existingUser: 'No',
    },
    validationSchema: Yup.object().shape({
      existingUser: Yup.string().required('Existing User is invalid'),
    }),
    onSubmit: (values) => {
      props.onClickNext(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }
  };

  const formValid = getFormValid({ ...formik });

  return (
    <BCSteps>
      <FormLabelWithField
        formControlProps={{ className: 'bc-step--field-question-control' }}
        className="bc-step--field-question"
        label="Are you an existing ServiceTitan user?"
        isRequired={false}
      >
        <Select
          className="bc-step--field-select"
          id="existingUser"
          defaultValue={formik.values.existingUser}
          options={existingUserOptions}
          formValid={formValid}
          values={formik.values}
          handleChange={onHandleChange}
          placeholder="Yes or No"
        />
      </FormLabelWithField>
      <div className="bc-step--button-group">
        <Button
          className="bc-step--button-back"
          type="white"
          shadow={false}
          onDebouncedClick={onClickBack}
        >
          Back
        </Button>
        <Button
          className="bc-step--button-next"
          type="secondary"
          shadow={false}
          onDebouncedClick={() => formik.submitForm()}
        >
          Finish and calculate
        </Button>
      </div>
    </BCSteps>
  );
};
