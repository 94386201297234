import React from 'react';
import { graphql } from 'gatsby';
import SEO, { createSEO } from 'components/Core/SEO';
import './style.less';
import BusinessCalculator from 'components/Tools/BusinessCalculator';

const BusinessCalcStep = (props) => {
  const seo = createSEO(props?.data?.contentfulSeo, props);

  return (
    <>
      <SEO {...seo} />
      <BusinessCalculator
        location={props.location}
        pagePrefix={props.pageContext.pagePrefix}
        {...props}
      />
    </>
  );
};

export default BusinessCalcStep;

export const BusinessCalcStepPageQuery = graphql`
  query BusinessCalcStepPageQuery($internalName: String!) {
    contentfulSeo(internalName: { eq: $internalName }) {
      ...Seo
    }
  }
`;
