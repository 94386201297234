import React, { useState, useEffect } from 'react';
import { createHistory } from '@reach/router';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { getSetCookies } from 'utils/cookieUtility';
import { formSubmittedEvent } from 'services/dataLayerService';
import { MultiStepDemoHeader } from 'components/Form/MultiStepDemoForm/MultiStepDemoHeader';
import Steps from 'components/Core/Steps';
import { StepRevenue } from 'components/Tools/BusinessCalculator/StepRevenue';
import { StepCostOfSale } from 'components/Tools/BusinessCalculator/StepCostOfSale';
import { StepOverhead } from 'components/Tools/BusinessCalculator/StepOverhead';
import { StepTotalAssets } from 'components/Tools/BusinessCalculator/StepTotalAssets';
import { StepEmployees } from 'components/Tools/BusinessCalculator/StepEmployees';
import { StepAdditional } from 'components/Tools/BusinessCalculator/StepAdditional';
import { StepCalculating } from 'components/Tools/BusinessCalculator/StepCalculating';
import { removeLastSlash } from 'utils/urlHelper';
import { StepContainer } from './styles';

import {
  navigateStep,
  convertResultParams,
  setDataLayerFields,
  updateCalculatedValues,
  fetchMarketoData,
} from './bcUtils';
import FadeTransitionRouter from '../FadeTransitionRouter';

const initValues = {
  industry: '',
  otherIndustry: '',
  state: '',
  revenue: '',
  costOfSale: '',
  overhead: '',
  totalAssets: '',
  officeStaffNumber: 0,
  techniciansNumber: 0,
  existingUser: '',
};

const BusinessCalculator = ({ location, pagePrefix, ...otherProps }) => {
  const pathArray = removeLastSlash(location?.pathname).split('/');
  const pathSlug = pathArray?.[pathArray?.length - 1];
  const initEmailValue = location.state ? location.state.valuefromSubscribeForm : null;
  const [multiStepDemoIndex, setMultiDemoIndex] = useState(0);
  const [values, setValues] = useState(initValues);
  const [isPrevNext, setPrevNext] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const tempValues = getSetCookies(values);
    setValues(tempValues);

    const history = createHistory(window);
    const backListener = history.listen((location) => {
      if (location.action === 'POP') {
        onBack();
      }
    });

    return () => {
      backListener();
    };
  }, []);

  const onNext = (newValues) => {
    if (isTransitioning) {
      return;
    }

    const tmpValues = { ...values, ...newValues };

    setValues(tmpValues);
    setPrevNext(1);
    setMultiDemoIndex((prevStepIndex) => prevStepIndex + 1);
  };

  const onBack = () => {
    if (isTransitioning) {
      return;
    }

    setPrevNext(0);
    setMultiDemoIndex((prevStepIndex) => prevStepIndex - 1);
  };

  const onSubmit = async (newValues, results) => {
    const updatedValues = { ...values, ...newValues, ...results };

    updateCalculatedValues(updatedValues);

    setValues(updatedValues);

    const resultUrl = `/${pagePrefix}-results?${convertResultParams(
      updatedValues,
    )}`;

    Bizible.Push('User', {
      eMail: updatedValues.email, // required
    });

    const dataLayerFields = setDataLayerFields(updatedValues, resultUrl);

    formSubmittedEvent({
      form_type: 'Business Calculator',
      ...dataLayerFields,
    });

    dataLayer.push({
      event: 'calculator',
      calculator_type: 'Business Calculator',
      ...dataLayerFields,
    });

    await fetchMarketoData('complete', updatedValues);
  };

  const incompleteSubmit = () => {
    navigate(`/${pagePrefix}`);
  };

  useEffect(() => {
    const { search } = location;
    navigateStep(search, multiStepDemoIndex, pagePrefix);
  }, [multiStepDemoIndex]);

  useEffect(() => {
    if (initEmailValue) {
      setValues((prevValues) => ({ ...prevValues, email: initEmailValue }));
    }
  }, []);

  return (
    <div className="bc-step--main-container data-layer-form">
      <MultiStepDemoHeader gobackPage={incompleteSubmit} />
      <StepContainer>
        <Steps
          steps={['Revenue', 'Cost of sale', 'Overhead', 'Total Assets', 'Employees', 'Additional']}
          activeStep={multiStepDemoIndex}
        />
        <FadeTransitionRouter
          stepIndex={multiStepDemoIndex}
          isPrevNext={isPrevNext}
          setIsTransitioning={setIsTransitioning}
        >
          {pathSlug === 'revenue' && (
            <StepRevenue
              onClickBack={onBack}
              onClickNext={onNext}
              values={values}
              path={`${pagePrefix}/:page`}
            />
          )}
          {pathSlug === 'cost-of-sale' && (
            <StepCostOfSale
              onClickBack={onBack}
              onClickNext={onNext}
              values={values}
              path={`${pagePrefix}/:page`}
            />
          )}
          {pathSlug === 'overhead' && (
            <StepOverhead
              onClickBack={onBack}
              onClickNext={onNext}
              values={values}
              path={`${pagePrefix}/:page`}
            />
          )}
          {pathSlug === 'total-assets' && (
            <StepTotalAssets
              onClickBack={onBack}
              onClickNext={onNext}
              values={values}
              path={`${pagePrefix}/:page`}
            />
          )}
          {pathSlug === 'employees' && (
            <StepEmployees
              onClickBack={onBack}
              onClickNext={onNext}
              values={values}
              path={`${pagePrefix}/:page`}
            />
          )}
          {pathSlug === 'additional' && (
            <StepAdditional
              onClickBack={onBack}
              onClickNext={onNext}
              values={values}
              path={`${pagePrefix}/:page`}
            />
          )}
          {pathSlug === 'calculating' && (
            <StepCalculating
              onSubmit={onSubmit}
              onClickNext={onNext}
              values={values}
              path={`${pagePrefix}/:page`}
            />
          )}
        </FadeTransitionRouter>
      </StepContainer>
    </div>
  );
};

export default BusinessCalculator;

BusinessCalculator.propTypes = {
  location: PropTypes.object.isRequired,
};
