/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useFormik } from 'formik';
import TextField from 'components/Core/TextField';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import { getFormValid } from 'utils/formUtils';
import { BCSteps } from '../styles';

export const StepCostOfSale = (props) => {
  const formik = useFormik({
    initialValues: {
      costOfSale: props.values.costOfSale,
    },
    validate: (values) => {
      if (!values.costOfSale || values.costOfSale <= 0) {
        return { costOfSale: 'Cost Of Sale is invalid' };
      }
      return {};
    },
    onSubmit: (values) => {
      props.onClickNext(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, parseFloat(newValue.replace(/\$|,/g, '')));
  };

  const onHandleKeyPressed = (event) => {
    if (event.key === 'Enter') {
      formik.submitForm();
    }
  };

  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }
  };

  const formValid = getFormValid({ ...formik });

  return (
    <BCSteps>
      <FormLabelWithField
        formControlProps={{ className: 'bc-step--field-question-control' }}
        className="bc-step--field-question"
        label="What was your cost of sales last year?"
        isRequired={false}
      />
      <p className="bc-step--field-description">
        Cost of sales includes all parts, materials, permits, field labor, and anything else that
        you can directly tie to a job. This does not include rent, office salaries, utilities, etc.
      </p>
      <TextField
        className="bc-step--field-text"
        id="costOfSale"
        type="currency"
        nameType="Cost of sale"
        placeholderText="Enter Amount"
        values={formik.values}
        formValid={formValid}
        formErrors={formik.errors}
        handleChange={onHandleChange}
        handleKeyPress={onHandleKeyPressed}
        autoFocus
      />
      <div className="bc-step--button-group">
        <Button
          className="bc-step--button-back"
          type="white"
          shadow={false}
          onDebouncedClick={onClickBack}
        >
          Back
        </Button>
        <Button
          className="bc-step--button-next"
          type="secondary"
          shadow={false}
          onDebouncedClick={() => formik.submitForm()}
        >
          Continue
        </Button>
      </div>
    </BCSteps>
  );
};
