/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'components/Core/Select';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import { getFormValid } from 'utils/formUtils';
import { BCSteps } from '../styles';

export const StepEmployees = (props) => {
  const officeStaffList = [
    { label: 'Select Office Staff', value: '' },
    { label: '0 Office Staff', value: '0' },
    { label: '1 Office Staff', value: '1' },
    { label: '2+ Office Staff', value: '2' },
  ];

  const techniciansList = [
    { label: 'Select Techs', value: '' },
    { label: '1 Technicians', value: '1' },
    { label: '2 Technicians', value: '2' },
    { label: '3 Technicians', value: '3' },
    { label: '4-9 Technicians', value: '9' },
    { label: '10-29 Technicians', value: '29' },
    { label: '30+ Technicians', value: '30' },
  ];

  const formik = useFormik({
    initialValues: {
      officeStaffNumber:
        props.values?.officeStaffNumber && props.values?.officeStaffNumber > 0
          ? props.values?.officeStaffNumber
          : undefined,
      techniciansNumber:
        props.values?.techniciansNumber && props.values?.techniciansNumber > 0
          ? props.values?.techniciansNumber
          : undefined,
    },
    validationSchema: Yup.object().shape({
      officeStaffNumber: Yup.string().trim().required('Office Staff Number is required'),
      techniciansNumber: Yup.string().trim().required('Technicians Number is required'),
    }),
    onSubmit: (values) => {
      props.onClickNext(values);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const onClickBack = () => {
    if (props.onClickBack) {
      props.onClickBack();
    }
  };

  const formValid = getFormValid({ ...formik });

  return (
    <BCSteps>
      <FormLabelWithField
        formControlProps={{ className: 'bc-step--field-question-control' }}
        className="bc-step--field-question"
        label="How many Office Staff work at your company?"
        helperText="How many full-time office employees (eg. manager, dispatchers, CSRs, etc) work at your company?"
        isRequired={false}
      >
        <Select
          className="bc-step--field-select"
          id="officeStaffNumber"
          placeholder="Select Office Staff"
          options={officeStaffList}
          formValid={formValid}
          values={formik.values}
          handleChange={onHandleChange}
        />
      </FormLabelWithField>
      <FormLabelWithField
        formControlProps={{ className: 'bc-step--field-question-control' }}
        className="bc-step--field-question"
        label="How many Technicians work at your company?"
        helperText="How many full-time technicians (e.g. plumbers, or electricians) work at your company? For best results, omit apprentices and helpers."
        isRequired={false}
      >
        <Select
          className="bc-step--field-select"
          id="techniciansNumber"
          placeholder="Select Techs"
          options={techniciansList}
          formValid={formValid}
          values={formik.values}
          handleChange={onHandleChange}
        />
      </FormLabelWithField>
      <div className="bc-step--button-group">
        <Button
          className="bc-step--button-back"
          type="white"
          shadow={false}
          onDebouncedClick={onClickBack}
        >
          Back
        </Button>
        <Button
          className="bc-step--button-next"
          type="secondary"
          shadow={false}
          onDebouncedClick={() => formik.submitForm()}
        >
          Continue
        </Button>
      </div>
    </BCSteps>
  );
};
