/* eslint-disable import/prefer-default-export */
import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from 'components/Core/TextField';
import Button from 'components/Core/Button';
import useBusinessCalcImages from 'hooks/useBusinessCalcImages';
import { getFormValid } from 'utils/formUtils';
import { BCSteps, ResultModal } from '../styles';

export const StepCalculating = (props) => {
  const pageImages = useBusinessCalcImages();
  const calcGif =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'BusinessCalc Calculating')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';
  const resultImg =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'BusinessCalc Result')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  const [results, setResults] = useState({
    grossProfit: 0,
    ebitda: 0,
    ebitdaPercent: 0,
    ebitdaHitMaxMultiplier: 4000000,
    ebitdaMinMultiplier: 2.8,
    ebitdaMaxMultiplier: 7,
    ebitdaMultiplier: 0,
    maximum: 0,
    upperQuartile: 0,
    medianEstimate: 0,
    lowerQuartile: 0,
    minimum: 0,
    newCompany: 0,
    totalIncrease: 0,
  });

  const [otherModal, setOtherModal] = useState(false);

  const showOtherModal = () => {
    setOtherModal(true);
  };

  const handleCancelOtherModal = (e) => {
    setOtherModal(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Email is invalid').required('Email is required'),
    }),
    onSubmit: (values) => {
      props.onSubmit(values, results);
    },
  });

  const onHandleChange = (id, newValue) => {
    formik.setFieldTouched(id);
    formik.setFieldValue(id, newValue);
  };

  const onHandleKeyPressed = (event) => {
    if (event.key === 'Enter') {
      formik.submitForm();
    }
  };

  useEffect(() => {
    const tmpValues = { ...results };
    tmpValues.grossProfit = props?.values?.revenue - props?.values?.costOfSale;
    tmpValues.ebitda = tmpValues.grossProfit - props?.values?.overhead;
    tmpValues.ebitdaPercent = parseFloat(
      ((tmpValues.ebitda / props.values?.revenue) * 100).toFixed(2),
    );

    tmpValues.ebitdaMultiplier =
      tmpValues.ebitda > tmpValues.ebitdaHitMaxMultiplier
        ? tmpValues.ebitdaMaxMultiplier
        : (tmpValues.ebitda / tmpValues.ebitdaHitMaxMultiplier) *
            (tmpValues.ebitdaMaxMultiplier - tmpValues.ebitdaMinMultiplier) +
          tmpValues.ebitdaMinMultiplier;
    tmpValues.medianEstimate = tmpValues.ebitda * tmpValues.ebitdaMultiplier;

    if (tmpValues.ebitda > 0 && tmpValues.medianEstimate > props.values.totalAssets) {
      tmpValues.maximum = tmpValues.medianEstimate * 1.3;
      tmpValues.upperQuartile = tmpValues.medianEstimate * 1.2;
      tmpValues.lowerQuartile = tmpValues.medianEstimate * 0.85;
      tmpValues.minimum = tmpValues.medianEstimate * 0.75;
    } else {
      if (tmpValues.medianEstimate < props?.values?.totalAssets) {
        tmpValues.medianEstimate = props?.values?.totalAssets;
      }

      tmpValues.maximum = tmpValues.medianEstimate * 1.1;
      tmpValues.upperQuartile = tmpValues.medianEstimate * 1.05;
      tmpValues.lowerQuartile = tmpValues.medianEstimate * 0.95;
      tmpValues.minimum = tmpValues.medianEstimate * 0.9;
    }

    const tmpValues1 = { ...results };
    tmpValues1.grossProfit = props?.values?.revenue * 1.24 - props?.values?.costOfSale;
    tmpValues1.ebitda = tmpValues1.grossProfit - props?.values?.overhead;
    tmpValues1.ebitdaPercent = parseFloat(
      ((tmpValues1.ebitda / props?.values?.revenue) * 100).toFixed(2),
    );
    tmpValues1.ebitdaMultiplier = tmpValues.ebitdaMultiplier;
    tmpValues1.medianEstimate = tmpValues1.ebitda * tmpValues1.ebitdaMultiplier;
    if (tmpValues1.ebitda > 0 && tmpValues1.medianEstimate > props?.values?.totalAssets) {
      tmpValues.newCompany = tmpValues1.medianEstimate * 1.1;
    } else {
      tmpValues1.medianEstimate = props?.values?.totalAssets * 1.24;
      tmpValues.newCompany = tmpValues1.medianEstimate * 1.05;
    }
    tmpValues.totalIncrease = tmpValues.newCompany - tmpValues.medianEstimate;
    setResults(tmpValues);

    setTimeout(() => {
      showOtherModal();
    }, props.waitTime || 4000);
  }, []);

  const formValid = getFormValid({ ...formik });

  return (
    <BCSteps>
      <img data-testid="bc_calc_gif" className="bc-step--field-img calc-gif" src={calcGif} />
      <p className="bc-step--field-description calc-description">Calculating business value...</p>
      <ResultModal
        onBackdropClick={handleCancelOtherModal}
        open={otherModal}
        handleClose={handleCancelOtherModal}
        width="520px"
      >
        <div className="calc-result-modal">
          <p className="bc-step--field-description calc-result-title font-heading-lg">
            Business Valuation Completed!
          </p>
          <img
            data-testid="bc_calc_image"
            className="bc-step--field-img calc-result-img"
            src={resultImg}
          />
          <p className="bc-step--field-description calc-result-description">
            Where would you like us to send a copy of your report?
          </p>
          <TextField
            className="bc-step--field-text"
            id="email"
            type="text"
            nameType="Email"
            placeholderText="Enter Business Email Address"
            values={formik.values}
            formValid={formValid}
            formErrors={formik.errors}
            handleChange={onHandleChange}
            handleKeyPress={onHandleKeyPressed}
          />
          <Button
            className="bc-step--button-next"
            type="primary"
            shadow={false}
            onDebouncedClick={() => formik.submitForm()}
          >
            Send Report
          </Button>
        </div>
      </ResultModal>
    </BCSteps>
  );
};
